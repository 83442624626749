import styled from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => theme.textStyles.montserratTextBase}
  color: ${({ theme }) => theme.colors.blueSecondaryBis};

  & p {
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }

  & h1 {
    ${({ theme }) => theme.textStyles.firaSansXl}
  }
  & h2 {
    ${({ theme }) => theme.textStyles.firaSans2XlBold}
    color: ${({ theme }) => theme.colors.bluePrimary};
  }
  & h3 {
    ${({ theme }) => theme.textStyles.firaSansXl}
    color: ${({ theme }) => theme.colors.bluePrimary};
  }
  & h4 {
    ${({ theme }) => theme.textStyles.firaSansTextBase}
  }
  & a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.ruby};
    font-style: italic;
    &:hover {
      text-decoration: underline;
    }
  }
  & strong,
  & b {
    font-weight: bold;
    font-variation-settings: 'wggt' 700;
  }
`
