import { DataProp } from 'editorjs-blocks-react-renderer'

import { api } from '../../configuration'

export const transformRichText = (text?: string): DataProp | undefined => {
  if (text) {
    try {
      const json: DataProp = JSON.parse(text) as DataProp
      json.blocks = json.blocks.map((block) => {
        if (block.type === 'image') {
          block.data.file.url = api.API_URL + block.data.file.url
        }
        return block
      })
      return json
    } catch (e) {
      console.log(e, text)
    }
  }
}
