import styled from 'styled-components'
import Image from 'next/image'

import { mq, sizes } from '../../theme'
import BoxedContainer from '../BoxedContainer'
import ActionButton from '../ActionButton'

export const HeroBanner = styled.div`
  position: relative;
  min-height: auto;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.brownBack};
  padding: 4.5rem 0;
  @media ${mq(sizes.desktop)} {
    padding: 6rem 0;
  }
`

export const BackgroundImage = styled(Image)`
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  inset: 0;
`

export const BannerWrapper = styled(BoxedContainer)``

export const Content = styled.div`
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('desktop')} {
    padding-left: 10%;
    padding-right: 5%;
  }
`

export const Title = styled.h1`
  & * {
    font-family: inherit;
    font-size: inherit;
    line-height: 1;
  }
  margin: 0;
  ${({ theme }) => theme.textStyles.firaSans5Xl};
  color: ${({ theme }) => theme.colors.blueSecondaryBis};

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.firaSans6Xl};
  }
`

export const Subtitle = styled.h2`
  margin: 0;
  ${({ theme }) => theme.textStyles.vujahdayXLarge};
  color: ${({ theme }) => theme.colors.pinkButton};

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.vujahdayXXLarge};
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.7rem;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.up('mobile')} {
    flex-direction: row;
  }
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.montserratLg};
  max-width: 60rem;
  width: 100%;

  @media ${mq(null, sizes.tablet)} {
    margin-top: 2rem;
  }
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.montserratXl};
  }
`

export const Button = styled(ActionButton)`
  white-space: nowrap;
`
