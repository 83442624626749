import { useMemo } from 'react'

import { RichTextProps } from '../../components/RichText'

import { transformRichText } from './transformRichText'

export const useRichText = (text?: string): RichTextProps | undefined => {
  return useMemo(() => {
    return {
      data: transformRichText(text),
    }
  }, [text])
}
