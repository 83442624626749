import React, { FC } from 'react'
import cx from 'classnames'
import { ImageProps } from 'next/image'

import { useRichText } from '../../relay/RichText/useRichText'
import RichText from '../RichText'
import { ActionButtonProps } from '../ActionButton'

import * as SC from './styled'

export type HeroBannerProps = {
  className?: string
  title: string
  subtitle: string
  text: string
  backgroundImage: ImageProps
  button?: ActionButtonProps
}

const HeroBanner: FC<HeroBannerProps> = ({
  className,
  title,
  subtitle,
  text,
  backgroundImage,
  button,
}) => {
  const richTextProps = useRichText(title)

  return (
    <SC.HeroBanner className={cx('HeroBanner', className)}>
      <SC.BackgroundImage
        {...backgroundImage}
        priority={true}
        width={1440}
        height={480}
      />
      <SC.BannerWrapper>
        <SC.Content>
          <SC.Title>
            <RichText {...richTextProps} />
          </SC.Title>
          <SC.Subtitle>{subtitle}</SC.Subtitle>
          <SC.Wrapper>
            <SC.Text>{text}</SC.Text>
            {button && <SC.Button {...button} />}
          </SC.Wrapper>
        </SC.Content>
      </SC.BannerWrapper>
    </SC.HeroBanner>
  )
}

export default HeroBanner
