import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ImageTransformer } from '../../Transformers/ImageTransformer'
import HeroBanner from '../../../components/HeroBanner'
import { BlockProps } from '../props'
import { selectors } from '../../../redux'
import Router from '../../../routes/Router'
import routes from '../../../routes/definitions'
import { ActionButtonVariant } from '../../../components/ActionButton/styled'

import { BannerProps } from './props'

const Banner: FC<BlockProps<BannerProps>> = ({ data }) => {
  const isLogin = useSelector(selectors.auth.isConnected)
  const imageProps = useMemo(
    () => ImageTransformer(data.image.data),
    [data.image.data]
  )

  return (
    <>
      {imageProps && (
        <HeroBanner
          {...data}
          text={data?.description}
          backgroundImage={imageProps}
          {...(data?.button
            ? {
                button: {
                  text: data?.button?.text,
                  href: isLogin
                    ? data?.button?.href
                    : Router.getRouteUrl(routes.loginOrRegister),
                  variant: ActionButtonVariant.quaternary,
                  target: data?.button?.target ?? '_self',
                },
              }
            : {})}
        />
      )}
    </>
  )
}
export default Banner
