import React, { FC } from 'react'
import Blocks, {
  ConfigProp,
  DataProp,
  RenderersProp,
} from 'editorjs-blocks-react-renderer'

import * as SC from './styled'

export type RichTextProps = {
  className?: string
  data?: DataProp
  config?: ConfigProp | undefined
  renderers?: RenderersProp | undefined
}

const RichText: FC<RichTextProps> = ({ className, data, ...props }) => {
  return data ? (
    <SC.Container className={className}>
      <Blocks {...props} data={data} />
    </SC.Container>
  ) : null
}

export default RichText
